import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLinks from "../components/social_links"

const IndexPage = () => (
  <Layout>
    <Seo title="Diensten" />
    <section className="page-header">
      <h1>Diensten</h1>
    </section>    
    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8 md:pb-28 xl:pb-40">Gratis oogtest</h2>
        <p>Onze ogen zijn een kostbaar goed. Om ze zo goed mogelijk te beschermen, is een oogmeting een belangrijke eerste stap.</p>
        <p>Onze gediplomeerde opticiens meten jouw ogen nauwkeurig met de revolutionaire DNEye® oogscanner. Zo hebben we in enkele ogenblikken een exact resultaat van 0.01/100 Dioptrie.</p>
        <p>Met de resultaten van jouw oogmeting gaan we samen op zoek naar de beste kijk oplossing voor jouw ogen. De test is volledig gratis bij aankoop van een bril.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/diensten/oogtest" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Meer informatie over oogtest ...">Meer info</Link>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/oogmeting_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogtest"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
        <StaticImage
            src="../images/oogmeting_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogtest"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        />
      </div>
      <div className="section-bg-left"></div>
    </section>

    <section className="section">
      <div className="section-content-left">
        <h2 className="pb-8 md:pb-28 xl:pb-40">
          Myopie management
        </h2>
        <p>Wat doen we het toch goed hè, voor onze kinderen? We geven ze liefde, gezond eten en aandacht. Maar hoe zorgen wij voor hun ogen?</p>
        <p>Bijziendheid komt steeds vaker voor, ook bij jongeren. Wist je dat op dit moment al zeker 50% van de Europese jongeren bijziend is? De ernst van het probleem wordt bovendien ook groter. De sterkste toename vindt meestal plaats tussen de leeftijd van 6 en 17 jaar oud. In deze groep is het gevaar op bijziendheid het grootst.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/diensten/myopiemanagement" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Meer informatie over myopie management ...">Meer info</Link>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-7 col-end-13 row-start-1 row-end-1 z-10 h-full flex justify-end items-end">
        <StaticImage
            src="../images/myopiemgmt_4.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Myopie management"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        />
        <StaticImage
            src="../images/myopiemgmt_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Myopie management"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right"></div>
    </section>
    
    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8 md:pb-28 xl:pb-40">Gehoorbescherming & hoortest</h2>
        <p>Gehoorverlies is nooit alleen een gehoorverlies. Het is ook vooral een sociaal probleem dat zorgt voor een verminderde levenskwaliteit. Want als uw gehoor vermindert, dan mist u heel wat mooie, unieke momenten.</p>
        <p>U kan bij ons ook terecht voor een gratis en vrijblijvende hoortest of onderhoud van uw hoorapparaten. Een audioloog van Audika helpt u graag verder met deskundig advies over gehoorverlies, hoorapparaten en gehoorbescherming.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/diensten/hoortest" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Meer informatie over hoortest ...">Meer info</Link>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/gehoorbescherming_amplifon_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Gehoorbescherming en hoortest"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
        <StaticImage
            src="../images/gehoorbescherming_audika_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Gehoorbescherming en hoortest"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        />
      </div>
      <div className="section-bg-left"></div>
    </section>

    <section className="section">
      <div className="section-content-left">
        <h2 className="pb-8 md:pb-28 xl:pb-40">
          Veiligheidsbrillen
        </h2>
        <p>Het oog is een zeer kwetsbaar lichaamsdeel en vaak is schade die eraan wordt toegebracht onomkeerbaar. De juiste bescherming van ogen en gelaat is cruciaal. Wij zorgen ervoor dat draagcomfort, optimale zichtbaarheid, maximale bescherming en uitgekiend design hand in hand gaan.</p>
        <p>Kortom gelaatsbescherming op maat!</p>
        <p>Alle oog- en gelaatsbeschermingsmiddelen zijn CE-typegekeurd volgens de voor de toepassing geldende Europese EN-normen.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-7 col-end-13 row-start-1 row-end-1 z-10 h-full flex justify-end items-end">
        <StaticImage
            src="../images/veiligheidsbrillen_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Veiligheidsbrillen"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        />
        <StaticImage
            src="../images/veiligheidsbrillen_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Veiligheidsbrillen"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right"></div>
    </section> 
</Layout>
)

export default IndexPage
